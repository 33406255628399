$body-bg: #FCFEFB;
$primary: #DEC023;
$secondary: #57585A;
$tertiary: #57585A;
$call_to_action_bg: $primary;
$search-home-bg-color: $secondary;
$search_overview-bg-color: $secondary;
$header-bg-color: white;
$mobile-header-color: #000;
$mobile-header-background: white;
$top_strip_color: $primary;
$top_strip_text_color: $secondary;
$navbar-dark-color: $secondary ;
$navbar-dark-hover-color: darken($secondary, 10) ;
$navbar-dark-active-color: darken($secondary, 10) ;
$header-text-color: $secondary;
$headings-color:$secondary;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;


@import "../../flean_base_2019/sass/imports";

.main-menu .nav li a{
  font-weight:600 !important;
}

#block-helper-search-search-holiday-homes input[type="submit"] {
  color: $secondary;
}

#header > .header.header-top-strip .top .container {
  color: $secondary !important;
}
